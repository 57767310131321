'use strict';


function processInvite(url) {
    if (url) {
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    if (data.html) {
                        $('#loginModal').modal('hide');
                        $('body').append(data.html);
                        $('#inviteDetailsModal').modal('show');
                    } else {
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                }
            }
        });
    }
}

function init() {
    let $inviteContainer = $('#js-invite-container');
    if ($inviteContainer.length > 0) {
        $.get($inviteContainer.attr('data-url'));
    }

    let $invitedProceedURL = $('#invitedProceedURL');
    if ($invitedProceedURL.length > 0) {
        processInvite($invitedProceedURL.val());
    }
}

module.exports = {
    init: init
};
